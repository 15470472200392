import React from 'react'
import Navbar from '../Components/Navbar/Navbar'
import Footer from '../Components/Footer/Footer'
import LoginSignin from '../Components/LoginSignup/LoginSignin'
import "./LoginComp.css"
import Viewcart from '../Reusable/ViewCart/Viewcart'
const LoginorSignup = () => {
  return (
    <>
    <Navbar/>
    <div className='LoginComp'>
   <div className='Logincomp1'>
   <LoginSignin/>
   </div>
   <div className='Logincomp3'>
    <div className='MostlyViewed'>
        <h3>Mostly Viewed</h3>
        <div className='MostlyViewed1'>
            <Viewcart/>
            <Viewcart/>
            <Viewcart/>
        </div>
    </div>
    <div className='MostlyViewed'>
        <h3>Recently Viewed</h3>
        <div className='MostlyViewed1'>
            <Viewcart/>
            <Viewcart/>
            <Viewcart/>
        </div>
    </div>
    <div className='MostlyViewed'>
        <h3>Top Products</h3>
        <div className='MostlyViewed1'>
            <Viewcart/>
            <Viewcart/>
        
        </div>
    </div>
    <div className='MostlyViewed'>
        <h3>Our Special Product</h3>
        <div className='MostlyViewed1'>
            <Viewcart/>
        </div>
    </div>
   </div>
    </div>
    <Footer/>
    </>
  )
}

export default LoginorSignup