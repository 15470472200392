import React from 'react'
import "./Viewcart.css"
import viercartimages from "../../Assets/images/AboutValueImg.svg"
const Viewcart = () => {
  return (
    <>
    <div className='checkviewcart'>
    <div className='checkviewcartimg'>
        <img src={viercartimages} alt="" />
    </div>
    <div className='checkviewcartcontent'>
        <h4>Lunik Rocket</h4>
        <p><span>&#8377; 168</span><del> &#8377; 98.8</del></p>
    </div>
    </div>
    </>
  )
}

export default Viewcart