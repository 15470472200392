/* eslint-disable no-unused-vars */
import React from 'react'
import{ useRef, useState } from 'react';
import { Swiper, SwiperSlide } from 'swiper/react';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import banner from "../../Assets/images/bannar1.avif"
import banner1 from "../../Assets/images/banner1r-02 1.png"
import banner2 from "../../Assets/images/banner2rrr-01 1.png"
import banner3 from "../../Assets/images/banner4-01 2.png"
import './Banner.css';
import { Pagination, Navigation, Autoplay } from 'swiper/modules';
const Banner = () => {
  return (
    <>
    <div className='Bannar'>
    <Swiper
        slidesPerView={1}
        spaceBetween={30}
        loop={true}
        pagination={{
          clickable: true,
        }}
        autoplay={{
          delay: 2500, 
          disableOnInteraction: false, 
        }}
        navigation={true}
        modules={[Pagination, Navigation,Autoplay]}
        className="mySwiper"
      >
        <SwiperSlide className='design'>
            <img src={banner3} alt="" />
        </SwiperSlide>
         <SwiperSlide className='design'>
            <img src={banner2} alt="" />
        </SwiperSlide>
        <SwiperSlide className='design'>
            <img src={banner1} alt="" />
        </SwiperSlide>
       
        
        <SwiperSlide className='design'>
            <img src={banner1} alt="" />
        </SwiperSlide>
        <SwiperSlide className='design'>
            <img src={banner2} alt="" />
        </SwiperSlide>
        <SwiperSlide className='design'>
            <img src={banner3} alt="" />
        </SwiperSlide>
        <SwiperSlide className='design'>
            <img src={banner1} alt="" />
        </SwiperSlide>
       
      </Swiper>

    </div>
    </>
  )
}

export default Banner