/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { BsInstagram } from "react-icons/bs";
import { FaYoutube } from "react-icons/fa";
import logo from "../../Assets/images/cracker logo-03 1.svg";
import "./Footer.css";
const Footer = () => {
  return (
    <div className="footer">
      <div className="footer1">
        <div className="footer2">
          <h2>NammaoorCrakersz</h2>
          <div className="navbarTitle">
            <img src={logo} alt="" />
          </div>
        </div>
        <div className="footercontent">
          <div className="f1">
            <p>
              In Sivakasi, India, NammaoorCrackersz is a leading cracker online
              shopping website and fireworks. We offer the best quality crackers
              company online all over India at unbeatable pricing.
            </p>
            <div className="social">
              <h1>Social Media</h1>
              <div className="social1">
                <a
                  href="https://www.instagram.com/luckystar_ganesh/"
                  target="_blank"
                >
                  <BsInstagram className="soicon" />
                  luckystar_ganesh
                </a>
                <a
                  href="https://www.youtube.com/@nammaoorcrackersz4650"
                  target="_blank"
                >
                  <FaYoutube className="soicon" />
                  https://www.youtube.com/@nammaoorcrackersz4650
                </a>
              </div>
            </div>
          </div>
          <div className="f2">
            <h2>Quick Links</h2>
            <h4 onClick={() => window.location.replace("/")}>Home</h4>
            <h4 onClick={() => window.location.replace("/about")}>About us</h4>
            <h4 onClick={() => window.location.replace("/products")}>
              Product
            </h4>
            <h4 onClick={() => window.location.replace("/contact")}>Contact</h4>
          </div>
          {/* <div className="f2">
            <h2>Categories</h2>
            <h4>Kids</h4>
            <h4>Family</h4>
            <h4>teenagers</h4>
            <h4>Gift Box</h4>
            <h4>Night display</h4>
          </div> */}
          <div className="f3">
            <h2>Contact Us</h2>
            <h4>
              Email: <span>luckystarganesh@gmail.com</span>
            </h4>
            <h4>
              Address :{" "}
              <span>
                Lucky Star Agencies, 740,Mundagan Street, Periyandavar Colony,
                Sivakasi - 626123, Tamil Nadu, India.
              </span>
            </h4>
            <h4>
              Phone : <span>9089898989</span>
            </h4>
          </div>
        </div>
      </div>
      <div className="footercontent1">
        <h5>
          © 2024 - NammaoorCrackersz | Designed and Developed by TechBuddy
        </h5>
      </div>
    </div>
  );
};

export default Footer;
