/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect } from "react";
import Navbar from "../Components/Navbar/Navbar";
import Marquee from "../Components/Marquee/Marquee";
import Banner from "../Components/Banner/Banner";
import Details from "../Components/Details/Details";
import Aboutus from "../Components/Aboutus/Aboutus";
import Categories from "../Components/Categories/Categories";
import Choose from "../Components/Choose/Choose";
import Footer from "../Components/Footer/Footer";
import CrakerBanner from "../Components/CrakerBanner/CreakerBanner";
import Ourservices from "../Components/OurServices/Ourservices";
import { storeAction } from "../Store/Store";
import { useDispatch } from "react-redux";

const Home = () => {
  const dispatch = useDispatch();
  useEffect(() => {
    Getalldata();
  }, []);
  const Getalldata = async () => {
    dispatch(storeAction.cartdataHandler({ cartdata: [] }));
    dispatch(storeAction.userdataHandler({ userdata: null }));
    dispatch(storeAction.stepHandler({ step: 1 }));
    dispatch(storeAction.paymentidHandler({ paymentid: null }));
    dispatch(storeAction.orderidHandler({ orderid: null }));
  };
  return (
    <>
      <Navbar />
      <Marquee />
      <Banner />
      <Details />
      {/* <CartsSwiper /> */}
      <Categories />
      <Ourservices />
      <Choose />
      <Aboutus />
      <CrakerBanner />
      <Footer />
    </>
  );
};

export default Home;
