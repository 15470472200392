import React from 'react'
import Crakerimg from "../../Assets/images/banner3c-01 1.png"
import "./Crakerbanner.css"
const CreakerBanner = () => {
  return (
    <>
    <div className='CrackerBanner'>
        <div className='CrakerBannner1'>
            <img src={Crakerimg} alt="" />
        </div>
    </div>
    </>
  )
}

export default CreakerBanner