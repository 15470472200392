/* eslint-disable react/jsx-no-target-blank */
import React from "react";
import { MdShoppingCartCheckout } from "react-icons/md";
import logo from "../../Assets/images/cracker logo-03 1.svg";
import { IoCloseSharp } from "react-icons/io5";
import { FaBars } from "react-icons/fa";
import "./Navbar.css";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";

const Navbar = () => {
  const cartdata = useSelector((state) => state.cartdata);
  function opendiv(params) {
    let getdiv = document.querySelector(".navbarlist");
    getdiv.classList.add("slider");
    let geticon = document.querySelector(".burgermenu");
    let geticon1 = document.querySelector(".burgermenu1");
    geticon.classList.add("close");
    geticon1.classList.add("cl");
  }
  function closediv(params) {
    let getdiv = document.querySelector(".navbarlist");
    getdiv.classList.remove("slider");
    let geticon = document.querySelector(".burgermenu");
    let geticon1 = document.querySelector(".burgermenu1");
    geticon.classList.remove("close");
    geticon1.classList.remove("cl");
  }
  const handleDownload = () => {
    window.open(
      "https://firebasestorage.googleapis.com/v0/b/first-1a905.appspot.com/o/LUCKYE%20STAR%20PRICE%20LIST%202024.pdf?alt=media&token=d301d3d1-9313-47ad-bf60-5ab8e1eccd4d",
      "_blank"
    );
  };
  const handleDownload1 = () => {
    window.open(
      "https://firebasestorage.googleapis.com/v0/b/first-1a905.appspot.com/o/LUCKY%20STAR%20CATLOG%202024.pdf?alt=media&token=afda8924-92b4-4d8c-a5d6-3c39d1604365",
      "_blank"
    );
  };
  return (
    <>
      <div className="navbar">
        <FaBars className="burgermenu" id="open" onClick={opendiv} />
        <IoCloseSharp className="burgermenu1" id="close" onClick={closediv} />
        <div className="navbarlist1">
          <Link to="/">
            <div className="navbarTitle">
              <img src={logo} alt="" />
            </div>
          </Link>
          <div className="navbarlist">
            <div className="navbarcontent">
              <Link to="/">
                <h4>Home</h4>
              </Link>
              <Link to="/about">
                <h4>About us</h4>
              </Link>
              <Link to="/products">
                <h4>Product</h4>
              </Link>

              <Link to="/contact">
                <h4>Contact</h4>
              </Link>
            </div>
            <div className="navbaricons">
              <button onClick={handleDownload}>Price List</button>
            </div>
            <div className="navbaricons">
              <button onClick={handleDownload1}>Price Catlog</button>
            </div>
            <div className="po12">
              <div className="po">
                {cartdata.length !== 0 ? (
                  <Link to="/mycart">
                    <MdShoppingCartCheckout className="ordericons" />
                    <span>
                      <p>{cartdata.length}</p>
                    </span>
                  </Link>
                ) : (
                  <div>
                    <MdShoppingCartCheckout className="ordericons" />
                    <span>
                      <p>0</p>
                    </span>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Navbar;
