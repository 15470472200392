import React from "react";
import Navbar from "../Navbar/Navbar";
import "./Contact.css";
import Footer from "../Footer/Footer";
const Contact = () => {
  return (
    <>
      <Navbar />
      <div className="ContactContainer">
        <div className="Contact">
          <h1 className="ContactHeading">Let's Get In Touch</h1>
          <p>We're open for any suggestion or just to have a chat</p>
          <h1 className="ContactSubHeading">Adderss</h1>
          <p className="AddressContent">
            Lucky Star Agencies, 740,Mundagan Street, Periyandavar Colony,
            Sivakasi - 626123,Tamil Nadu,India.
          </p>
          <h1 className="ContactSubHeading">Mobile Number</h1>
          <p>
            <span>Mobile: </span>+91 9894660660
          </p>
          <p>
            <span>Office: </span>+91 9790560660
          </p>
          {/* <h1 className='ContactSubHeading'>Email Address</h1>
                <p>xyz12@gmail.com</p> */}
          <h1 className="ContactSubHeading">Opening Time</h1>
          <p>Monday – Friday: 9am – 9pm</p>
          <p>Saturday – Sunday: 9am – 11pm</p>
        </div>
      </div>

      <Footer />
    </>
  );
};

export default Contact;
